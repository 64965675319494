import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alarma: 0,  
  };
    
export const alarmSlice = createSlice({
    name: 'alarmsSlice',
    initialState,
        reducers: {
            AlarmPost: (state, action) => {
                state.alarma = action.payload;
                //console.log(state.cheker)
            },
            
            
            

            
            
            resetAlarm: ( state ) => 
                    initialState,
        }
})

export const { AlarmPost, resetAlarm } = alarmSlice.actions;
export const selectAlarm = (state) => state.alarmsState;
export default alarmSlice.reducer

