import React, { useEffect } from 'react';
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { getCuentasRequest } from '../../api/cuenta';
import { addCont, selectCuenta } from '../../features/cuentas/cuentaSlide';
import { HomeCuenta } from './Table/HomeCuenta';

export function CuentaPage() {
  const dispatch = useDispatch();
  const cuentas = useSelector(selectCuenta);

  // Fetch data initially and every interval to keep it updated
  useEffect(() => {
    const fetchCuentas = async () => {
      try {
        const resc = await getCuentasRequest();
        dispatch(addCont(resc.data));
      } catch (error) {
        console.error("Error fetching cuentas:", error);
      }
    };

    fetchCuentas(); // Initial fetch
    const timer = setInterval(fetchCuentas, 2000); // Periodic fetch every 2 seconds

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [dispatch]);

  // Filter habilitado cuentas (enabled accounts)
  const cuentasVisor = cuentas.filter(x => x.nombre !== "inicial");
  
  return (
    <Box>
      {cuentasVisor.length > 0 ? (
        <HomeCuenta cuentasVisor={cuentasVisor} />
      ) : (
        <Box>
          <Typography color="textSecondary" sx={{ fontSize: "18px", fontWeight: "800" }}>
            Cargando cuentas .........
          </Typography>
        </Box>
      )}
    </Box>
  );
}
