import React, { useEffect, useState } from "react";
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { getHistoryEvent } from '../../../api/history';

const GraHistorico = ({ params }) => {
  const cuentas = useSelector(selectCuenta);
  const cuenta = cuentas.filter(x => x._id === params.cuentaId);
  
  const [gaugeData, setGaugeData] = useState({});
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        id: 'chart2',
        type: 'line',
        height: 230,
        dropShadow: {
          enabled: true,
          enabledOnSeries: [1]
        },
        toolbar: {
          autoSelected: 'pan',
          show: false
        }
      },
      colors: ['#008FFB', '#00E396'],
      stroke: {
        width: [2, 2],
        curve: ['straight', 'monotoneCubic']
      },
      fill: {
        opacity: [1, 0.75],
      },
      markers: {
        size: 0
      },
      yaxis: [        
        {
          seriesName: 'Vbat',
          axisTicks: {
            show: true,
            color: '#008FFB'
          },
          axisBorder: {
            show: true,
            color: '#008FFB'
          },
          labels: {
            style: {
              colors: '#008FFB',
            }
          },
          title: {
            text: "Vbat",
            style: {
              color: '#008FFB'
            }
          },
          min: 0,
          max: 30,
          tickAmount: 5
        },
        {
          seriesName: 'Corriente',
          opposite: true,
          axisTicks: {
            show: true,
            color: '#00E396'
          },
          axisBorder: {
            show: true,
            color: '#00E396'
          },
          labels: {
            style: {
              colors: '#00E396'
            }
          },
          title: {
            text: "Corriente",
            style: {
              color: '#00E396'
            }
          },
          min: 0,
          max: 3,
          tickAmount: 5
        }
      ],
      xaxis: {
        categories: [] ,// Asignaremos aquí los valores de Xdata
        labels: {
          style: {
            fontSize: '10px' // Aquí reducimos el tamaño de la fuente del eje X
          },
          rotate: -45, // Gira las etiquetas si es necesario para más espacio
            formatter: (value, index) => {
              // Mostrar cada N-ésima etiqueta
              return index % 10 === 0 ? value : ''; // Salta las etiquetas entre cada 5
            }
        }
      },
    },




    seriesLine: [{
      name: 'Vbat',
      data: []
    }, {
      name: 'Corriente',
      data: []
    }],
    optionsLine: {
      chart: {
        id: 'chart1',
        height: 130,
        type: 'area',
        brush: {
          target: 'chart2',
          enabled: true
        },
        selection: {
          enabled: true,
        },
      },
      colors: ['#008FFB', '#00E396'],
      stroke: {
        width: [1, 2],
        curve: ['straight', 'monotoneCubic']
      },
      fill: {
        type: 'gradient',
        gradient: {
          opacityFrom: 0.91,
          opacityTo: 0.1,
        }
      },
      xaxis: {
        tooltip: {
          enabled: false
        },
        labels: {
          style: {
            fontSize: '6px' // Aquí reducimos el tamaño de la fuente del eje X
          },
          rotate: -45, // Gira las etiquetas si es necesario para más espacio
            formatter: (value, index) => {
              // Mostrar cada N-ésima etiqueta
              return index % 10 === 0 ? value : ''; // Salta las etiquetas entre cada 5
            }
        }
      },
      yaxis: [
        {
          seriesName: 'Vbat',
          min: 0,
          max: 30,
          tickAmount: 2,
        },
        {
          seriesName: 'Corriente',
          opposite: true,
          min: 0,
          max: 3,
          tickAmount: 5
        }
      ]
     
    }
  });

  const fetchData = async () => {
    const his = await getHistoryEvent(cuenta[0].nroorden);
    const Vbat = [];
    const i1m = [];
    const xvi = [];
    const dataMFinal = his.data.length;

    console.log(dataMFinal)

    if (dataMFinal > 2) {
      for (let i = 0; i < 300; i++) {
        const item = his.data[i];
        Vbat.push(item.vbat);
        i1m.push(item.ic);
        const fecha = new Date(item.createdAt);
        let horasArgentina = fecha.getUTCHours() - 3;
        if (horasArgentina < 0) {
          horasArgentina += 24;
        }
        const horas = horasArgentina.toString().padStart(2, '0');
        const minutos = fecha.getUTCMinutes().toString().padStart(2, '0');

        const horaFormateada = `${horas}:${minutos}`;


        const dia = fecha.getUTCDate().toString().padStart(2, '0');
        const mes = (fecha.getUTCMonth() + 1).toString().padStart(2, '0');
        const ano = fecha.getUTCFullYear();
        const dosUltimosDigitosAno = ano % 100;

        const fechaFormateada = `${dia}/${mes}/${dosUltimosDigitosAno}`;
        xvi.push(fechaFormateada + ' ' + horaFormateada);
      }
    }

    setGaugeData({
      Vbat: Vbat.reverse(),
      i1m: i1m.reverse(),
      xvi: xvi.reverse(),
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (gaugeData.Vbat && gaugeData.xvi && gaugeData.Vbat.length > 0 && gaugeData.xvi.length > 0) {
      const updatedState = {
        ...state,
        series: [{
          name: 'Vbat',
          data: gaugeData.Vbat.map(Number) // Asegúrate de que sean numéricos
        }, {
          name: 'Corriente',
          data: gaugeData.i1m
        }],
        options: {
          ...state.options,
          xaxis: {
            categories: gaugeData.xvi // Asignar valores de Xdata
          },
        },
        seriesLine: [{
          name: 'Vbat',
          data: gaugeData.Vbat
        }, {
          name: 'Corriente',
          data: gaugeData.i1m
        }],
        optionsLine: {
          ...state.optionsLine,
          xaxis: {
            categories: gaugeData.xvi // Asignar valores de Xdata
          },
        }
      };
      setState(updatedState);
    }
  }, [gaugeData]);

  // Verifica si gaugeData está vacío antes de intentar renderizar el gráfico
  if (!gaugeData.Vbat || !gaugeData.xvi) {
    return <div>Loading...</div>; // Mostrar un mensaje de carga si no hay datos
  }

  return (
    <div>
      <div id="wrapper">
        {/* Primer gráfico: Gráfico principal */}
        <div id="chart-line2">
          <ReactApexChart options={state.options} series={state.series} type="line" height={330} width={900}/>
        </div>

        {/* Segundo gráfico: Gráfico de selección */}
        <div id="chart-line">
          <ReactApexChart options={state.optionsLine} series={state.seriesLine} type="area" height={230} />
        </div>
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default GraHistorico;
