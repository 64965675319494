import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Chip, IconButton } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

const tableHeaderStyle = {
  fontSize: "14px",
  fontWeight: "800",
  color: "textSecondary",
};

const tableCellStyle = {
  fontSize: "10px",
  fontWeight: "500",
};

const TableHeader = () => {
  const headers = [
    "Ir",
    "Cuenta",
    "Nombre",
    "Vbat",
    "Status",
    "Corr",    
    "Tipo",
    "Tiempo",
    "Veces",
    "Error",
    "Estado",
    "Online",
  ];

  return (
    <TableHead>
      <TableRow>
        {headers.map((header) => (
          <TableCell key={header}>
            <Typography sx={tableHeaderStyle}>{header}</Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const TableRowData = ({ data, navigate }) => (
  <TableRow key={data.cuentaTabla}>
    <TableCell>
      <IconButton aria-label="navigate" onClick={() => navigate(`/dash/${data.cuentaId}`)}>
        <EastIcon />
      </IconButton>
    </TableCell>
    {[
      data.cuentaTabla,
      data.nombreTabla,
      data.vbat,
      data.charg,
      data.ic,
      data.type,
      data.c_seg,
      data.c_marcha,
      data.error,
    ].map((value, index) => (
      <TableCell key={index}>
        <Typography sx={tableCellStyle}>{value}</Typography>
      </TableCell>
    ))}
    <TableCell>
      <Chip
        sx={{
          pl: "4px",
          pr: "4px",
          backgroundColor: data.backcells,
          color: "#1a237e",
          fontSize: "10px",
        }}
        size="small"
        label={data.statust}
      />
    </TableCell>
    <TableCell>
      <Chip
        sx={{
          pl: "4px",
          pr: "4px",
          backgroundColor: data.difcells,
          color: "#1a237e",
          fontSize: "10px",
        }}
        size="small"
        label={data.fecha}
      />
    </TableCell>
  </TableRow>
);

export const HomeTable = ({ tablas }) => {
  const navigate = useNavigate();

  //console.log(tablas)

  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHeader />
      <TableBody>
        {tablas.map((x) => (
          <TableRowData key={x.cuentaTabla} data={x} navigate={navigate} />
        ))}
      </TableBody>
      
      
    </Table>
  );
};