import React, { useState } from 'react';
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import {
  Box,
  Drawer,
  useMediaQuery,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@mui/material";
import { SidebarWidth } from "../../assets/global/Theme-variable";
import Menuitems from "./data";
import LogoIcon from "../../image/logo.jpg";
import Image from 'mui-image';

export function Sidebar(props) {
  const [open, setOpen] = useState(null); // Usamos el índice de un item abierto
  const { pathname } = useLocation();
  const pathDirect = pathname;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  // Función para manejar la expansión de subitems
  const handleClick = (index) => {
    setOpen(open === index ? null : index); // Alternar visibilidad de subitems
  };

  const SidebarContent = (
    <Box sx={{ p: 3, height: "calc(100vh - 40px)" }}>
      <Link to="/">
        <Box sx={{ display: "flex", alignItems: "Center" }}>
          <img alt="Logo" src={LogoIcon} width={140}/>
        </Box>
      </Link>
      <Box>
        <List sx={{ mt: 2 }}>
          {Menuitems.map((item, index) => (
            <div key={item.title}>
              <ListItem
                onClick={() => handleClick(index)} // Alternar subitems
                button
                component={NavLink}
                to={item.href}
                selected={pathDirect === item.href}
                sx={{
                  mb: 1,
                  ...(pathDirect === item.href && {
                    color: "white",
                    backgroundColor: (theme) =>
                      `${theme.palette.primary.main}!important`,
                  }),
                }}
              >
                <ListItemIcon
                  sx={{
                    ...(pathDirect === item.href && { color: "white" }),
                  }}
                >
                  <item.icon width="18" height="18" />
                </ListItemIcon>
                <Typography color="textSecondary" sx={{ fontSize: "12px", fontWeight: "600" }}>
                  {item.title}
                </Typography>
              </ListItem>

              {/* Si tiene subitems, los mostramos */}
              {item.subItems && open === index && (
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => (
                    <ListItem
                      key={subIndex}
                      button
                      component={NavLink}
                      to={subItem.href}
                      sx={{
                        pl: 4, // Indentamos los subitems
                        ...(pathDirect === subItem.href && {
                          color: "white",
                          backgroundColor: (theme) =>
                            `${theme.palette.primary.main}!important`,
                        }),
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ...(pathDirect === subItem.href && { color: "white" }),
                        }}
                      >
                        {/* Icono de subitems (opcional, o puedes dejar vacío) */}
                      </ListItemIcon>
                      <Typography color="textSecondary" sx={{ fontSize: "12px", fontWeight: "600" }}>
                        {subItem.title}
                      </Typography>
                    </ListItem>
                  ))}
                </List>
              )}
            </div>
          ))}
        </List>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open={props.isSidebarOpen}
        variant="persistent"
        PaperProps={{
          sx: {
            width: SidebarWidth,
          },
        }}
      >
        {SidebarContent}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      open={props.isMobileSidebarOpen}
      onClose={props.onSidebarClose}
      PaperProps={{
        sx: {
          width: SidebarWidth,
        },
      }}
      variant="temporary"
    >
      {SidebarContent}
    </Drawer>
  );
}