import React from "react";
import Chart from "react-apexcharts";

const Corriente = ({i1m, xvolt, volt}) => {

  //console.log('corriente', xvolt);

  const series = [
    {
      name: "Vbat",
      data: volt
    },
    {
      name: "Corr",
      data: i1m
    },
  ];

  const options = {
    chart: {
      height: 300,
      type: 'area',
      animations: {
        enabled: false, // Desactiva las animaciones
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      
      categories: xvolt,
      labels: {
        rotate: -45, // Rota las etiquetas para que se lean verticalmente
        style: {
          colors: '#000',
          fontSize: '12px',
          fontFamily: 'Arial, sans-serif',
        },
      },
    },
    yaxis: [
      {
        // Primer eje Y (para 'Vbat')
        title: {
          text: 'Vbat'
        },
        labels: {
          formatter: function (value) {
            return value + " V";
          }
        },
        min: 0, // Establece el valor mínimo del eje Y
        max: 30, // Establece el valor máximo del eje Y
      },
      
      {
        // Segundo eje Y (para 'Corr')
        opposite: true, // Coloca el segundo eje Y en el lado derecho
        title: {
          text: 'Corr'
        },
        labels: {
          formatter: function (value) {
            return value + " A"; // Asumiendo que la corriente está en amperios
          }
        },
        min: 0, // Establece el valor mínimo del eje Y
        max: 3, // Establece el valor máximo del eje Y
      }
    ]
  };

        
      
    
      return <Chart options={options} series={series} type="area" height={260} width={660} />;
    };

export default Corriente;