import React, {useState, useEffect } from 'react';
import { 
  Box, 
  Button,
  Card, 
  CardHeader,   
  CardContent, 
  Typography, FormControlLabel, Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { selectJob } from '../../../features/jobs/jobSlide';
import { useParams } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import moment from 'moment';



export function BoxJob({cuentaBox}) {
 
  
  const cuentas = useSelector(selectCuenta)
  const jobs = useSelector(selectJob)
  const navigate = useNavigate();   

  const jobResult = jobs.filter(job => job.cuentaID === cuentaBox)
 
  //console.log(jobs)

 
  
  
    
    //console.log('card', postResult[0]._id, results, jobResult);
    

  return (
    <Box sx={{ height: 200}}
    
    >
        <Table
        aria-label="simple table"
        sx={{
          mt: 3,
          whiteSpace: "nowrap",
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell><Typography color="textSecondary" sx={{
                    fontSize: "12px", fontWeight: "600"}}>Fecha
                </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                    fontSize: "12px", fontWeight: "600"}}>Dispositivo
                </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                    fontSize: "12px", fontWeight: "600"}}>Tecnico
                </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                    fontSize: "12px", fontWeight: "600"}}>Garantia
                </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                    fontSize: "12px", fontWeight: "600"}}>Remito
                </Typography></TableCell>
              
          </TableRow>
        </TableHead>
        <TableBody>
          {jobResult.map((x) => (
            <TableRow key={x._id}>
              <TableCell><Typography sx={{
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                >
                  {(new Date(x.createdAt)).toLocaleDateString('es-ES', { year: 'numeric', month: 'numeric', day: 'numeric' })}
                </Typography></TableCell>
                <TableCell><Typography sx={{
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                >
                  {x.dispositivo}
                </Typography></TableCell>
                <TableCell><Typography sx={{
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                >
                  {x.tecnico}
                </Typography></TableCell>
                <TableCell><Typography sx={{
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                >
                  {x.garantia}
                </Typography></TableCell>
                <TableCell><Typography sx={{
                    fontSize: "10px",
                    fontWeight: "500",
                  }}
                >
                  {x.remito}
                </Typography></TableCell>
                
            </TableRow>
          ))}
        </TableBody>
        </Table>

    </Box>
   
  )
    
}



