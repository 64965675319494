import React, {useState, useEffect } from 'react';
import { Box, Button, Card, Grid, Paper, TextField, useColorScheme, useTheme, Typography, MenuItem, Switch } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
//import { pubpush }  from '../../../../api/mqtt';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function BoxCuenta({results}) {
    
    //console.log(results);
    //const cuentas = useSelector(selectCuenta);    
    //const cuentaResult = cuentas.filter(x => x.nroorden === results)  ;

    return (<>    
    <Box
        padding={1}       
    >
      <Grid 
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start" 
        //marginTop={3}                   
        >
            <Grid>
                <Typography  variant="subtitle2" color='primary' gutterBottom >
                
                </Typography>
            </Grid>
            <Grid >
                <Typography  variant='body2'  gutterBottom>Direccion: {results.nroorden}</Typography>
            </Grid>  
            <Grid >
                <Typography  variant='body2'  gutterBottom>Direccion: {results.direccion}</Typography>
            </Grid>            
                <Box>
                    <Typography variant='body2'  gutterBottom>Telefono: {results.telefono}</Typography>
                </Box>
                <Box>
                    <Typography variant='body2'  gutterBottom>Equipo: {results.equipo}</Typography>
                </Box>           
            <Grid >
                <Typography  variant='body2'  gutterBottom>Mantenimiento:  {results.mantenimiento}</Typography>
            </Grid> 
            
        </Grid>  
    </Box>         
    </>);
  }


