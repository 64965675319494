import React, { useEffect, useState } from 'react';
import { Box, Grid, Button, Paper, Typography, Card, CardContent } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { selectPost } from '../../features/post/postSlide';
import { selectCuenta } from '../../features/cuentas/cuentaSlide';
import { useNavigate, useParams } from "react-router-dom";
import { getPostsDashRequest } from '../../api/post';
import { BoxCuenta } from './Box/BoxCuenta';
import { BoxVoltaje } from './Box/BoxVoltaje';
import { BoxJob } from './Box/BoxJob';
import { BoxSeguridad } from './Box/BoxSeguridad';
import { getVistasRequest } from '../../api/vista';
import GraHistory  from './Graph/GraHistorico';




export function DashHistory() {

    const params = useParams();
    const cuentas = useSelector(selectCuenta);
    const [ muestra, setMuestra] = useState(15)
    const navigate = useNavigate(); 
    //console.log(params.id)
    const cuentaId = params.id

    const p15 = () => {      
      navigate(`/dashH/${params.id}`);
    }

    const p30 = () => {      
      navigate(`/dashH30/${params.id}`); 
    }

    const p60 = () => {      
      navigate(`/dashH60/${params.id}`);
    }

  return (

    <Box>
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        

        
            <GraHistory params={{ cuentaId, muestra }}/>
          
            
          

        


      </Grid>
        
    </Box>

    
  )
}


