//https://codesandbox.io/s/react-leaflet-with-marker-cluster-8kb8b0?file=/src/App.js:2274-2425
// con filtro por  icono https://codesandbox.io/s/react-leaflet-markercluster-getting-started-y6dkx0?file=/src/App.js:256-373
// https://icons8.com/icons/set/address

import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { MapContainer, TileLayer, useMap, Marker, Map, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  InputBase, 
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Typography,  
} from "@mui/material";
import L from "leaflet";
import { useSelector } from 'react-redux';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import blue from '../../../image/blue.png';
import red from '../../../image/red.png';
import lila from '../../../image/lila.png';
// iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
import AccessibleOutlinedIcon from '@mui/icons-material/AccessibleOutlined';




const customMarkerblue = new L.icon({
  iconUrl: blue,  
  iconSize: [33, 36],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const customMarkerred = new L.icon({
  iconUrl: red,  
  iconSize: [33, 36],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const customMarkerlila = new L.icon({
  iconUrl: lila,  
  iconSize: [33, 36],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40]
});

const position = [-34.61410073802194, -58.4576782531552]



export function MapComponent({ results }) {
  const cuentas = useSelector(selectCuenta)
  const navigate = useNavigate(); 

  const cuentaVer = cuentas.filter(x => x.nroorden === results)

  console.log(cuentaVer)
  
   const PO = cuentaVer.filter(cuenta => cuenta.equipo === "PO")
  //console.log('PO:', PO)
  const SRN = cuentaVer.filter(cuenta => cuenta.equipo === "SRN")
  //console.log('SRN:', SRN)
  const PVH = cuentaVer.filter(cuenta => cuenta.equipo === "PVH")
  //console.log('PVH:', PVH)

  

  /*
  const IconoSelec = () => {
    const PVH = cuentas.filter(cuenta => {(cuenta.equipo === "PVH"); return SetMarkericono( customMarkerred)})
    }
    */


  return (
    <Box 
      padding={2}
    >
      <MapContainer center={position} zoom={9} style={{ width: "100%", height: "80vh" }} >
      
      <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
       
          <MarkerClusterGroup>
            {PO.map((PO) => {
              return (
                <Marker 
                  key={PO.nroorden}
                  position={[PO.xgeo, PO.ygeo]}
                  icon={customMarkerblue}
                >
                  <Popup
                    maxWidth={90}
                  >
                    <Typography margin='2px' sx={{ fontSize: 10 }} gutterBottom>
                      {PO.nroorden} - {PO.nombre} -  {PO.direccion}                    
                    </Typography>
                    <IconButton
                        color = "secondary"
                        onClick={()=> navigate(`/eve/${PO._id}`)}                          
                      >  
                      <AccessibleOutlinedIcon />                      
                      </IconButton> 
                  </Popup>                 
                </Marker>
              )}
            )}            
          </MarkerClusterGroup>

          <MarkerClusterGroup>
            {SRN.map((SRN) => {
              return (
                <Marker 
                  key={SRN.nroorden}
                  position={[SRN.xgeo, SRN.ygeo]}
                  icon={customMarkerred}
                >
                  <Popup
                    maxWidth={90}
                  >
                    <Typography margin='2px' sx={{ fontSize: 10 }} gutterBottom>
                      {SRN.nroorden} - {SRN.nombre} -  {SRN.direccion}                    
                    </Typography>
                    <IconButton
                        color = "secondary"
                        onClick={()=> navigate(`/eve/${SRN._id}`)}                          
                      >  
                      <AccessibleOutlinedIcon />                      
                      </IconButton> 
                  </Popup>                 
                </Marker>
              )}
            )}            
          </MarkerClusterGroup>

          <MarkerClusterGroup>
            {SRN.map((SRN) => {
              return (
                <Marker 
                  key={SRN.nroorden}
                  position={[SRN.xgeo, SRN.ygeo]}
                  icon={customMarkerred}
                >
                  <Popup
                    maxWidth={90}
                  >
                    <Typography margin='2px' sx={{ fontSize: 10 }} gutterBottom>
                      {SRN.nroorden} - {SRN.nombre} -  {SRN.direccion}                    
                    </Typography>
                    <IconButton
                        color = "secondary"
                        onClick={()=> navigate(`/eve/${SRN._id}`)}                          
                      >  
                      <AccessibleOutlinedIcon />                      
                      </IconButton> 
                  </Popup>                 
                </Marker>
              )}
            )}            
          </MarkerClusterGroup>

          <MarkerClusterGroup>
            {PVH.map((PVH) => {
              return (
                <Marker 
                  key={PVH.nroorden}
                  position={[PVH.xgeo, PVH.ygeo]}
                  icon={customMarkerlila}
                >
                  <Popup
                    maxWidth={120}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography margin='2px' sx={{ fontSize: 10 }} gutterBottom>
                        {PVH.nroorden} - {PVH.nombre} -  {PVH.direccion}                
                      </Typography>
                      <IconButton
                        color = "secondary"
                        onClick={()=> navigate(`/eve/${PVH._id}`)} 
                      >                      
                          <AccessibleOutlinedIcon />                      
                      </IconButton>
                    </Grid>
                  </Popup>                 
                </Marker>
              )}
            )}            
          </MarkerClusterGroup>
      </MapContainer>
    </Box>
  )
}



