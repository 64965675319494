import React from 'react';
import {
  Box, 
  Paper, 
  
} from "@mui/material";
import { MapComponent } from './Map';
import { useNavigate, useParams } from "react-router-dom";

export function MapPageDash() {

  const params = useParams();
  console.log('mappage', params.id);

  return (
    <Box 
      height={300}
      width={1200}
      marginLeft={10}
      marginTop={2}
      //bgcolor='pink'
      >
        <Paper elevation={1} square={false} >
          <MapComponent results={params.id}/>
        </Paper>
        
        
       
     
    </Box>
  )
}


