import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { selectPost } from '../../../features/post/postSlide';
import { selectCuenta } from '../../../features/cuentas/cuentaSlide';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getVistasRequest } from '../../../api/vista';
import { getCuentasRequest } from "../../../api/cuenta";
import EastIcon from '@mui/icons-material/East';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';


import CircularProgress from '@mui/material/CircularProgress';
import {
  Typography,
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,  
} from "@mui/material";

export const HomeCuenta = ({cuentasVisor,}) => {

  const navigate = useNavigate();
  //console.log(cuentasVisor, fecha)

  //const fecha = new Date(vistaTabla[0].updatedAt).toLocaleString('en-GB')


  return (
    <Table
      aria-label="simple table"
      sx={{
        mt: 3,
        whiteSpace: "nowrap",
      }}
    >
      <TableHead>
        <TableRow>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Editar
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Tareas
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Eventos
            </Typography></TableCell>

            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Equipo
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Cuenta
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Nombre
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Direccion
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Telefono
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Fecha
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Habi
            </Typography></TableCell>
            <TableCell><Typography color="textSecondary" sx={{
                fontSize: "14px", fontWeight: "800"}}>Mant
            </Typography></TableCell>          
        </TableRow>
      </TableHead> 
      <TableBody>
      {cuentasVisor.map((x) => (
        <TableRow key={x._id}>
          <TableCell>
            <IconButton aria-label="delete" onClick={()=> navigate(`/cuentaform/${x._id}`)}>
              <EditOutlinedIcon color='primary'/>
            </IconButton>              
          </TableCell>
          <TableCell>
            <IconButton aria-label="delete" onClick={()=> navigate(`/cue/${x._id}`)}>
              <HandymanOutlinedIcon color='primary'/>
            </IconButton>              
          </TableCell>          
            <TableCell>
              <IconButton aria-label="delete" onClick={()=> navigate(`/dash/${x._id}`)}>
                <HistoryEduOutlinedIcon color='secondary'/>
              </IconButton>              
            </TableCell>
          
          
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.equipo}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.nroorden}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.nombre}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.direccion}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.telefono}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            { () => { new Date(x.createdAt).toLocaleString('en-GB')}}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.habilitado}
          </Typography></TableCell>
          <TableCell><Typography sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                }}
              >
            {x.mantenimiento}
          </Typography></TableCell>
        </TableRow>
        ))}
      </TableBody>     
    </Table>
  );
};


