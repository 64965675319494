import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { getCuentaRequest, createCuentaRequest, updateCuentaRequest } from "../../api/cuenta";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, TextField, Typography, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { editCuenta } from "../../features/cuentas/cuentaSlide";
import { useAuth } from "../../context/AuthContext";

// Definición de opciones
const equipos = [
  { value: "PO", label: "PO" },
  { value: "POA", label: "POA" },
  { value: "PT", label: "PT" },
  { value: "SRN", label: "SRN" },
  { value: "PVH", label: "PVH" },
  { value: "PVHPA", label: "PVHPA" },
];

const mantenimiento = [
  { value: "no", label: "no" },
  { value: "Mensual", label: "Mensual" },
  { value: "Bimensual", label: "Bimensual" },
  { value: "Trimensual", label: "Trimensual" },
  { value: "Semestral", label: "Semestral" },
];

const habilitacion = [
  { value: "desa", label: "Desabilitado" },
  { value: "habi", label: "Habilitado" },
];

export function CuentaForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();

  // Estado para almacenar la cuenta
  const [cuenta, setCuenta] = useState({
    nombre: "",
    direccion: "",
    xgeo: "",
    ygeo: "",
    telefono: "",
    fechainstala: "",
    detalle: "",
    equipo: "",
    mantenimiento: "",
    nroorden: "",
    habilitado: "",
  });

  // Cargar la cuenta si se está editando
  useEffect(() => {
    if (id) {
      (async () => {
        const res = await getCuentaRequest(id);
        setCuenta({ ...res.data, user: user.id }); // Simplificado
      })();
    }
  }, [id, user.id]);

  const handleSubmit = async (values, actions) => {
    try {
      if (id) {
        await updateCuentaRequest(id, values);
        dispatch(editCuenta({ ...values, id }));
      } else {
        await createCuentaRequest(values);
      }
      actions.resetForm();
      navigate('/cuenta');
    } catch (error) {
      console.error("Error al guardar los datos:", error);
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <Box marginTop={5} maxWidth={800}>
      <Typography variant="h5" color="primary">
        {id ? "Editar cliente" : "Ingrese nuevo cliente"}
      </Typography>

      <Formik
        initialValues={cuenta}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ handleChange, handleSubmit, values }) => (
          <Form onSubmit={handleSubmit}>
            <Box m="40px" display="grid" gap="30px">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nombre"
                onChange={handleChange}
                value={values.nombre}
                name="nombre"
                sx={{ gridColumn: "span 4" }}
                autoFocus
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Nro de Orden"
                onChange={handleChange}
                value={values.nroorden}
                name="nroorden"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Direccion"
                onChange={handleChange}
                value={values.direccion}
                name="direccion"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Telefono"
                onChange={handleChange}
                value={values.telefono}
                name="telefono"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="XGeo"
                onChange={handleChange}
                value={values.xgeo}
                name="xgeo"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="YGeo"
                onChange={handleChange}
                value={values.ygeo}
                name="ygeo"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Fecha Instalacion"
                onChange={handleChange}
                value={values.fechainstala}
                name="fechainstala"
                sx={{ gridColumn: "span 1" }}
              />
              <TextField
                select
                label="Habilitado"
                onChange={handleChange}
                value={values.habilitado}
                name="habilitado"
                sx={{ gridColumn: "span 1" }}
              >
                {habilitacion.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Equipo"
                onChange={handleChange}
                value={values.equipo}
                name="equipo"
                sx={{ gridColumn: "span 1" }}
              >
                {equipos.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Mantenimiento"
                onChange={handleChange}
                value={values.mantenimiento}
                name="mantenimiento"
                sx={{ gridColumn: "span 1" }}
              >
                {mantenimiento.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Button type="submit" variant="outlined" sx={{ mx: 60, my: 2 }}>
              Guardar
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
