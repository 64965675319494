import './App.css';

import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
//import { ProtectedRoute } from "./page/Security/ProtectedRoute";
import { HomePage, Navbar, Sidebar, LoginPage , RegisterPage, ProtectedRoute, VisorPage, CuentaPage, KeyPc, DelItem, SetItem,
  EventosPage, MonitorPage, CuentaForm, MapPage, UsuarioGral, MonitorForm, CuentaCardOnly, JobForm, Header, HistoryGraph,
  HomeMovil, DashHistory, TablaVariable, FormVariable, AlarmaPage, EventGraphMap, MonitorVisor, MapPageDash,
  HistoryPage, Grafico10, VisorJob, DashPage  
} from "./page";
import {
  Box,
  Container, 
  Grid, 
  Stack,
  createTheme, 
  experimentalStyled,
  ThemeProvider 
} from "@mui/material";
import { useSelector, useDispatch } from 'react-redux';
import { PostProvider } from "./context/PostContext";
import { VariableProvider } from './context/VariablesContext';
import { HistoryProvider } from './context/HistoryContext';
import {baseTheme} from './assets/global/Theme-variable';
import { TopbarHeight } from "./assets/global/Theme-variable";
//import { DashPage } from './page/NewDash/DashPage';

function App() {
  const dispatch = useDispatch(); 
  const [ checked, setCheck ] = useState(false)
  const cheker = useSelector((state) => state.checksState.cheker);
  const [ mode, setMode ] = useState('light')
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const theme = baseTheme;

  const MainWrapper = experimentalStyled("div")(({ theme }) => ({
    display: "flex",
    minHeight: "100vh",
    overflow: "hidden",
    width: "100%",
  }));

  const PageWrapper = experimentalStyled("div")(({ theme }) => ({
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",  
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up("lg")]: {
      paddingTop: TopbarHeight,
    },
    [theme.breakpoints.down("lg")]: {
      paddingTop: "64px",
    },
  }));
  
  
  useEffect(() =>
  {
    if (cheker === false) {
      setMode('light');
    } else {
      setMode('dark');
    }
  }, [])
  //console.log('app', cheker, mode)
  
  const darkTheme = createTheme({
    palette: {
      mode: mode,
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <Box bgcolor={"background.default"} color={"text.primary"}>
        <AuthProvider>
        < PostProvider>
          <VariableProvider>
            <HistoryProvider>
              <BrowserRouter>  


                      <Box>
                        <Header
                                sx={{
                                  paddingLeft: isSidebarOpen ? "200px" : "",
                                  backgroundColor: "#e8f5e9",
                                }}
                                toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
                                toggleMobileSidebar={() => setMobileSidebarOpen(true)}
                              />                           
                        <Sidebar 
                            isSidebarOpen={isSidebarOpen}
                            isMobileSidebarOpen={isMobileSidebarOpen}
                            onSidebarClose={() => setMobileSidebarOpen(false)}                            
                          />
                            <PageWrapper>
                                  <Container
                                    maxWidth={false}
                                    sx={{
                                      
                                      paddingLeft: isSidebarOpen ? "200px!important" : "",
                                    }}
                                    
                                  >
                                    <Box sx={{ minHeight: "calc(100vh - 170px)" }}
                                      marginTop={6}
                                      marginLeft={2}
                                    >
                                      <Routes>
                                        <Route path="/login" element={<LoginPage />} />                             
                                        <Route path='*' element={<Navigate to='/login' replace />} />  
                                        <Route path="/reg" element={<RegisterPage/>} />
                                          <Route element={<ProtectedRoute/>}>
                                          <Route path="/home" element={<HomePage/>} />
                                              <Route path="/casa" element={<HomePage/>} />
                                              <Route path="/vis" element={<VisorPage/>} />
                                              <Route path="/cuenta" element={<CuentaPage/>} />
                                              <Route path="/cuentaform" element={<CuentaForm/>} />
                                              <Route path="/cuentaform/:id" element={<CuentaForm/>} />
                                              <Route path="/cue/:id" element={<CuentaCardOnly/>}/>
                                              <Route path="/set" element={< TablaVariable />} /> 
                                              <Route path="/varform" element={< FormVariable />} />
                                              <Route path="/map" element={< MapPage />} />
                                              <Route path="/usu" element={< UsuarioGral />} />
                                              <Route path="/mon" element={< MonitorPage />} />
                                              <Route path="/eve/:id" element={<EventGraphMap/>}/>
                                              <Route path="/gra10/:id" element={<Grafico10/>}/>
                                              <Route path="/his" element={<HistoryPage/>}/>
                                              <Route path="/hisg" element={<HistoryGraph/>}/> 
                                              <Route path="/job/:id" element={< JobForm />} />  
                                              <Route path="/job" element={ < VisorJob /> }  />  
                                              <Route path="/dash/:id" element={<DashPage/>} /> 
                                              <Route path="/dashH/:id" element={<DashHistory/>} /> 
                                              <Route path="/map/:id" element={<MapPageDash/>} />
                                              <Route path="/mom" element={<MonitorVisor/>} />                                              
                                              <Route path="/KeyPc" element={<KeyPc/>} />
                                              <Route path="/seti" element={<SetItem/>} /> 
                                              <Route path="/deli" element={<DelItem/>} />                                               
                                          </Route>
                                      </Routes>
                                    </Box>
                                  </Container>
                                </PageWrapper>






                                                
                      </Box>                    
              </BrowserRouter>
            </HistoryProvider>
          </VariableProvider>
          </PostProvider>
        </AuthProvider>
      </Box>
      </ThemeProvider>
  );
}

export default App;
